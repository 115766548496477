.hero {
  background: url('./rust-bg.jpg') no-repeat center center/cover;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  color: white;
}

.hero-overlay {
  background-color: rgba(18, 18, 33, 0.7);
  padding: 50px;
  border-radius: 10px;
  text-align: center;
  max-width: 80vw;
  width: 35vw;
}

.hero-logo {
  width: 150px;
  height: auto;
}

.hero h1 {
  font-size: 2.5rem;
  margin-top: 20px;
}

.hero p {
  font-size: 1.2rem;
  margin-top: 10px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FF8110;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.server-card-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  max-width: 80vw;
  z-index: 1000;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;

  img {
    width: 50px;
    height: 50px;


    transition: filter 0.3s;

  }
}