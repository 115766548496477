.section {
  padding: 60px 20px;
}

.section h2 {
  font-size: 2rem;
  color: #FF8110;
}

.section ul {
  font-size: 1.2rem;
  list-style-type: decimal;
  padding: 0;
  text-align: center;

}

.section li {
  padding: 10px 0;
  margin: 10px 0;
}
