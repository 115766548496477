body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #121221;
  color: white;
  scroll-behavior: smooth;
  
}

.App {
  text-align: center;
}
::-webkit-scrollbar {
    display: none;
  }  