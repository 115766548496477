.store-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20vw;
    margin: 1rem;
    height: 80;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;

    a {
        text-decoration: none;
        color: white;
        background-color: #FF8110;
        padding: 0.5rem;
        border-radius: 0.5rem;
        text-align: center;
        margin-bottom: 5px;
        
    }
}

.store-item:hover {
    box-shadow: 0 0 10px 0 #FF8110;
    border: 1px solid #FF8110;
    transform: scale(1.02);
    transition: all 0.3s ease-in-out;
}