.faq-article {
    margin-bottom: 1rem;
    width: 50vw;
    display: flex;
    flex-direction: column;    
    a {
        color: #FF8110;
        text-decoration: none;
    
    }
}

.faq-article:first-child {
    margin-top: 5vh;
}

.faq-question {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #FF8110;
    color: white;
    border: 2px solid #FF8110;

    /* Round the top corners */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.faq-answer {
    font-size: 1.2rem;
    border: 2px solid #FF8110;
    padding: 1rem;
    /* Round the bottom corners */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}