.server-card {
    width: 20vw;
    
    border: 1px solid #FFD067;
    border-radius: 10px;
    min-width: 20vw;
    flex-wrap: wrap;

}
.header-item {
    img {
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
    }

    h3 {
        margin: 0;
        padding: 10px;
        color: #FFD067;
        background-color: #1A1A1A;
        border-radius: 0 0 10px 10px;
    }
}

.updated-text {
    color: #FFD067;
    font-size: 0.8rem;
    margin: 0;
    padding: 10px;
    border-radius: 0 0 10px 10px;

}

.server-details {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}

.button-container {
    display: flex;
    justify-content: center;
    /* Pin the buttons to the bottom of the card */

    margin-bottom: 0;
    bottom: 0;
    
    margin-top: auto;

    button {
        padding: 10px 20px;
        background-color: #FF8110;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        margin: 10px;
        justify-content: center;
        align-items: center;
        
    }

}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1500;
}

.server-name {
    color: #FFD067;
    font-size: 1.5rem;
    margin: 0;

}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 700px;
    position: relative;
    border-radius: 10px;
    background-color: rgb(22,22,22);
    z-index: 1500;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    z-index: 1500;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.map-image {
    width: 100%;
    height: auto;
}