.footer {
  background-color: #121221;
  padding: 20px;
  text-align: center;
}

.social-links a {
  color: #FFD067;
  margin: 0 10px;
  text-decoration: none;
}

.social-links a:hover {
  color: #FF8110;
}
.legal-links {
  color: #FFD067;
  font-size: 0.8rem;
  margin-top: 10px;

  a{
    color: #FFD067;
    text-decoration: none;
    padding: 0 5px;    
  }
}