.staff-brief {
    margin-top: 2vw;
    margin-bottom: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}

.staff-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 5vw;
    max-width: 50vw;
    padding: 0;
}

.staff-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    padding: 1vw;
    border: 2px solid #ff8c34;
    border-radius: 5px;
    max-width: 10vw;
    box-shadow: 0 0 10px 0 #ff8c34;
    animation: animated_shadow 2s infinite;

    h1 {
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
        margin: 0;
    }
    h3 {
        font-size: 1em;
        font-weight: bold;
        color: #fff;
        margin: 0;
    
    }
}

@keyframes animated_shadow {
    0% {
        box-shadow: 0 0 10px 0 #ff8c34;
    }
    50% {
        box-shadow: 0 0 20px 0 #ff8c34;
    }
    100% {
        box-shadow: 0 0 10px 0 #ff8c34;
    }
}