table {
  width: 90vw;
  border-collapse: collapse;
  border-spacing: 0;
  justify-content: center;

  
}

th {
  background-color: #ff8110;
  color: #333;
  font-weight: bold;
}

th {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

td {
  border-bottom: 1px solid #ddd;

  
}

.player-tbl {
    align-items: center;
    gap: 10px;

    img {
        width: 75px;
        height: 75px;
        border-radius: 10%;
    }

    h5 {
        font-size: 1.5rem;
    
    }
}


.bal-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    
    img{
        width: 50px;
        height: 50px;
    
    }
}



.gather-img {
        width: 50px;
        height: 50px;
  }

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h6 {
  width: 70px; /* Set a fixed width for the labels */
  text-align: right; /* Align text to the right for better spacing */
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the container */
  gap: 5px; /* Adjust gap between items as needed */
  padding: 0; /* Remove padding */
  padding-top: 10px;
  padding-bottom: 10px;
}

.rating-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px; /* Adjust gap between label and progress bar as needed */
  margin: 0; /* Remove extra margins */
  padding: 0; /* Remove extra padding */
  width: 100%;
}

.rating-item h6 {
  padding-right: 5px; /* Add some space between heading and progress bar */
}

.progress-bar-container {
  width: 100%;
}

th:first-child {
  border-top-left-radius: 10px;
}

th:last-child {
  border-top-right-radius: 10px;
}

.rank-gold {
  background-color: #ffd700;
}

.rank-silver {
  background-color: #c0c0c0;
}

.rank-bronze {
  background-color: #cd7f32;
}