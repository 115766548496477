@font-face {
    font-family: 'College';
    src: url('../Assets/Fonts/College.otf') format('opentype');
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    position: relative;
}

.navbar {
    display: flex;
    z-index: 999;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: rgb(22,22,22);
    width: 90vw;
    margin: 0 auto;
    color: #fff;
    border-radius: 25px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    position: sticky;
    top: 20px;
    animation: pulsing_nav 2s infinite;
    
}

.navbar-logo img {
    height: 50px;
}

.navbar-links {
    display: flex;
    gap: 1rem;
    font: 1rem 'College', sans-serif;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
}

.navbar-links a {
    color: #fff;
    text-decoration: none;
}

.navbar-login {
    display: none;
}
.navbar-avatar {
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.navbar-profile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.navbar-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: rgb(22,22,22);
    color: #FFD067;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.navbar-subscriptions {
    display: none;
    margin-top: 1rem;
}

.navbar-subscriptions table {
    width: 100%;
    border-collapse: collapse;
    background-color: rgb(33,33,33);
    color: #fff;
}

.navbar-subscriptions th, .navbar-subscriptions td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
    background-color: rgb(22,22,22);
}

.navbar-subscriptions th {
    background-color: rgb(22,22,22);
}

.navbar-logout, .navbar-subscriptions button {
    background: none;
    border: none;
    color: #fff;
    font: inherit;
    
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.navbar-logout:hover, .navbar-subscriptions button:hover {
    text-decoration: underline;
}

.navbar-links a:hover {
    text-decoration: underline;
    transform: scale(1.1);
    color: #FFD067;
}

.navbar-leaderboard-dropdown {
    position: absolute;
    top: 100%;
    background: rgb(22,22,22);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #FFD067;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1500;

}

.navbar-leaderboard:hover {
    text-decoration: underline;
    color: #FFD067;
    cursor: pointer;

}

@keyframes pulsing_nav {
    0% {
        box-shadow: 0 0 10px 0 #FF8110;
    } 
    50% {
        box-shadow: 0 0 20px 0 #f0882c;
    }
    100% {
        box-shadow: 0 0 10px 0 #FF8110;
    }
}

/* Make the navbar responsive */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }

    .navbar-links {
        flex-direction: column;
        gap: 1rem;
    }

    .navbar-logo {
        display: none;
    }

    .navbar-avatar {
        display: none;
    }

    .navbar-profile {
        display: none;
    }

    .navbar-dropdown {
        display: none;
    }

    .navbar-leaderboard-dropdown {
        display: none;
    }

    .navbar-links a {
        text-align: center;
    }
}