.section {
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  max-width: 50vw;
}

.section h2 {
  font-size: 2rem;
  color: #FF8110;
}

.section p {
  font-size: 1.2rem;
}

.about-logo {
  width: 50vw;
  margin: 0 auto;
}
