@font-face {
    font-family: 'College';
    src: url('../Assets/Fonts/College.otf') format('opentype');
}

.empty-space {
    text-transform: uppercase;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    font-family: 'College';


    img {
        width: 25vw;
        height: auto;
    }

    h1 {
        font-size: 6rem;
        margin-top: 20px;
    }
    h3 {
        font-size: 2rem;
        margin-top: 20px;
    }
}